div.dt-container .dt-paging .dt-paging-button {
  padding: 0;
  border: none !important;
  background-color: transparent !important;
  background: transparent !important;
}

.overflow-x{
  overflow-x: scroll;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  body {
    padding-top: 156px;
  }

  .search_form {
    border-radius: 0;
  }

  .search_form .d-flex {
    flex-direction: column;
  }

  .search_form .border-left {
    height: 1px !important;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .search_form .flex-fill {
    width: 100% !important;
  }

  .search_form .search_submit_btn {
    width: 100%;
    height: auto;
    border: 1px solid #dc3545;
  }

  .search_form .search_submit_btn button {
    width: 100%;
    border-radius: 0 !important;
    position: relative;
    top: 0;
    right: 0;
    transform: none;
  }

  .search_form .input_overlay {
    top: auto;
    bottom: 100%;
    border-radius: 0;
  }

  .search_form .autosuggest_search_box .react-autosuggest__suggestions-container,
  .search_form .autosuggest_search_box .react-autosuggest__suggestions-container ul {
    border-radius: 0;
    margin: 0;
    border-radius: 0;
  }

  .search-bar-wrapper {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
  }

  .copy {
    margin-bottom: 59px;
  }
}

.overflow-x-auto{
  overflow-x: auto;
}

.db-left-1 {
  padding: 116px 50px 30px 50px;
  background: url('assets/images/404224388_357211480154838_5092976306690138408_n.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
}

.db-cent-1 {
  padding: 116px 50px 30px 50px;
  background: url('assets/images/470471907_600743802468270_3728830093181670998_n.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
}

.text-bold{
  font-weight: bold;
  color: #333;
}

.text-secondary-d1 {
  color: #728299!important;
}

.brc-default-l1 {
  border-color: #dce9f0!important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem!important;
}
.mr-n1, .mx-n1 {
  margin-right: -.25rem!important;
}
.mb-4, .my-4 {
  margin-bottom: 1.5rem!important;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

.text-grey-m2 {
  color: #888a8d!important;
}

.text-success-m2 {
  color: #86bd68!important;
}

.font-bolder, .text-600 {
  font-weight: 600!important;
}

.text-110 {
  font-size: 110%!important;
}
.text-blue {
  color: #478fcc!important;
}
.pb-25, .py-25 {
  padding-bottom: .75rem!important;
}

.pt-25, .py-25 {
  padding-top: .75rem!important;
}
.bgc-default-tp1 {
  background-color: rgba(121,169,197,.92)!important;
}
.bgc-default-l4, .bgc-h-default-l4:hover {
  background-color: #f3f8fa!important;
}
.page-header .page-tools {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.btn-light {
  color: #757984;
  background-color: #f5f6f9;
  border-color: #dddfe4;
}
.w-2 {
  width: 1rem;
}

.text-120 {
  font-size: 120%!important;
}
.text-primary-m1 {
  color: #4087d4!important;
}

.text-danger-m1 {
  color: #dd4949!important;
}
.text-blue-m2 {
  color: #68a3d5!important;
}
.text-150 {
  font-size: 150%!important;
}
.text-60 {
  font-size: 60%!important;
}
.text-grey-m1 {
  color: #7b7d81!important;
}
.align-bottom {
  vertical-align: bottom!important;
}